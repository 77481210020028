import React,{useEffect} from 'react'
import classNames from "classnames";
import { Link } from "react-router-dom";
import config from '../../lib/config';
import kukuapi from "../../ABI/KUKU";
import wethabi from '../../ABI/BEP721'
// material ui
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Typography  } from "@material-ui/core";
import { MenuOpen, Notifications, AccountBalanceWallet, MeetingRoom } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import { getprovider } from '../../views/separate/walletconnect';

const HeaderDashboard =(props)=> {
    const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } = props;
    const [currAddr, setcurrAddr] = React.useState("");
    const [blns, setBln]          = React.useState(0);
    const [tokenbalance, settokenbalance]        = React.useState(0);
    const [wethbalance, setwethbalance]          = React.useState(0);


  useEffect(() => {
    getconnect();
  }, []);
    async function getconnect() {
     var {provider,address} = await getprovider();
     console.log(provider,"provider");
      if (provider) {
        var web3 = new Web3(provider);
        try {
          if (typeof provider !== "undefined") {
               
                  setcurrAddr(address);
                      var balance= await  web3.eth.getBalance(address)
                      var currbal = balance / 1000000000000000000;
                      setBln(currbal.toFixed(5));
                      var CoursetroContract = new web3.eth.Contract(kukuapi,config.lotteryToken);
                      var tokenDethBln = await CoursetroContract.methods.balanceOf(address).call();
                      var value = tokenDethBln / 1000000000000000000;
                      settokenbalance(value.toFixed(8));
                      var CoursetroContract1 = new web3.eth.Contract(wethabi,config.WbnbtokenAddr);
                      var tokenDethBln1 = await CoursetroContract1.methods.balanceOf(address).call();
                      var value1 = tokenDethBln1 / 1000000000000000000;
                      setwethbalance(value1.toFixed(8));
          } else {
          //  toast.success("Please Add Metamask External", toasterOption);
          }
        } catch (err) {}
      } else {
       // toast.success("Please Add Metamask External", toasterOption);
      }
    }
    async function disconnectMetamask() {
      if (localStorage.getItem("tfnukukpannft")) {
        localStorage.removeItem("tfnukukpannft");
        localStorage.removeItem('walltype');
        localStorage.removeItem('account');
        localStorage.removeItem('walletconnect');
        window.location.reload(false)
      } 
    }

  return (
    <div>
      <AppBar
          position="fixed"
          className={classes.appBar + " dash_header"}
          fooJon={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={true}>
          <div className="topbar_left">          
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <MenuOpen
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
            <a href="/"><img src={require("../../assets/images/logo.png")} alt="Logo" className="logo_icon"/></a>
          </div>
          <div className="topBarRight">
            {(!localStorage.getItem("tfnukukpannft"))?(
              <Button className="primary_btn" data-toggle="modal" data-target="#wallet_modal">Connect Wallet</Button>
              // <Button className="primary_btn">Connect Wallet</Button>
              ):(
                <div>
                <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">{currAddr}</Typography>
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          <MenuItem>
            <div className="dropProfilePic">
              <Typography className="dropProfileName"><span>Balance in BNB</span>: {blns}</Typography>
              <Typography className="dropProfileName"><span>Balance in {config.tokenSymbol}</span>: {tokenbalance}</Typography>
              {/* <Typography className="dropProfileName"><span>Address</span>: {currAddr}</Typography> */}
              {/* <Typography className="dropProfileName"><span>Balance in {config.equalTokenSymbol}</span>: {wethbalance}</Typography> */}
            </div>
          </MenuItem>
          {/* <MenuItem>
            <a href="javascript:void(0)" className="text-dark">My-Items</a>
            </MenuItem>  */}
            <MenuItem onClick={()=>disconnectMetamask()}><MeetingRoom /> Logout</MenuItem>
          </Menu>
            </div>
            )
          }
            {/* <a href="#"><Notifications /></a> */}
           {/*  <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</Typography>
            </IconButton>*/}

            
          </div>
        </Toolbar>
        
      </AppBar>

      

    </div>
)
}

export default HeaderDashboard;