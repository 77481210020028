import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import './index.css';
import './nft.css';
// import Myitems from "views/my-items";
import Lottery from "views/lottery";
// import About from "views/about";
// import Contact from "views/contact";
// import Privacy from "views/privacy";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter basename="/">
    <ToastContainer />
    <Switch>
      <Route path="/" component={Lottery} />
      <Route exact path="/*" component={Lottery}>
        <Redirect to="/" />
      </Route>     
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
