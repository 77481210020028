import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes, { func } from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import { getprovider } from "./separate/walletconnect";
import axios from "axios";
import config from "../lib/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LotteryABI from "../ABI/Lottery.json";
import LotterytokenABI from "../ABI/Lotterytoken.json";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import moment from "moment";
import { getCurAddr } from "../actions/v1/User";
import Web3 from "web3";
import "@metamask/legacy-web3";
import ConnectWallet from "./separate/Connect-Wallet";
import { toast } from "react-toastify";
toast.configure();
const drawerWidth = 240;
var randomstring = require("randomstring");
let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
});

const Lottery = (props) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [Accounts, Set_Accounts] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [ValidateError, Set_ValidateError] = React.useState({});
  const [YouWillGet, Set_YouWillGet] = React.useState(0);
  const [ApproveCallStatus, Set__ApproveCallStatus] = React.useState("init");

  const [Endlotterytime, SetEndlotteryTime] = React.useState(null);
  const [StartlotteryTime, SetStartlotteryTime] = React.useState(new Date());
  const [lotteryAmt, Set_lotteryAmt] = React.useState("");
  const [timerStart, setTimerStart] = React.useState(0);
  const [lotteryPrice, Set_lotteryPrice] = React.useState("");
  const [lotteryPrizeAmount, SetlotteryPrizeAmount] = React.useState(0);
  const [currentLottery, SetcurrentLottery] = React.useState("");
  const [userTicketCount, SetuserTicketCount] = React.useState("");
  const [rewardsBreakdown, SetrewardsBreakdown] = React.useState("");

  const [historyId, SethistoryId] = React.useState("");
  const [hisEndlotterytime, SethisEndlotteryTime] = React.useState(new Date());
  const [hisStartlotteryTime, SethisStartlotteryTime] = React.useState();
  const [hislotteryPrice, Set_hislotteryPrice] = React.useState("");
  const [hislotteryPrizeAmount, SethislotteryPrizeAmount] = React.useState(0);
  const [hisrewardsBreakdown, SethisrewardsBreakdown] = React.useState("");
  const [hisuserTicketCount, SethisuserTicketCount] = React.useState("");

  const [incss, setincss] = React.useState("none");
  const [outcss, setoutcss] = React.useState("block");

  const [TicStartlotteryTime, SetTicStartlotteryTime] = React.useState(
    new Date()
  );
  const [TicEndlotteryTime, SetTicEndlotteryTime] = React.useState();
  const [TiclotteryPrice, Set_TiclotteryPrice] = React.useState("");
  const [TiclotteryPrizeAmount, SetTiclotteryPrizeAmount] = React.useState("");
  const [TicrewardsBreakdown, SetTicrewardsBreakdown] = React.useState("");
  const [TicuserTicketCount, SetTicuserTicketCount] = React.useState("");
  const [TicfinalNumber, SetTicfinalNumber] = React.useState([]);
  const [Ticid, SetTicid] = React.useState("");
  const [claim, setclaim] = React.useState();
  const [loader, setloader] = React.useState(false);
  const [allHist, SetallHist] = React.useState([]);
  const [provider, setprovider] = React.useState();
  const [address, setaddress] = React.useState();
  const [finalNumber, SetfinalNumber] = React.useState([]);
  const [Allowance, setAllowance] = React.useState();
  const [currentWinningNumber, SetcurrentWinningNumber] = React.useState([]);
  const [currentWinningNumberId, SetcurrentWinningNumberId] = React.useState(
    []
  );
  useEffect(() => {
    getmyprovider();
    if (provider && address) {
      getTokenbal();
      getLotteryDet();
      // getclaimstatus();
      getallownce();
    }
  }, [provider, address]);

  async function getmyprovider() {
    var { provider, address } = await getprovider();
    setprovider(provider);
    setaddress(address);
  }

  async function getallownce() {
    // var {provider, address} = await getprovider();
    if (provider) {
      let web3 = new Web3(provider);
      // let currAddrALL = await web3.eth.getAccounts();
      let currAddr = address;
      let Tokencontract = new web3.eth.Contract(LotterytokenABI, config.lotteryToken);

      let viewTokenDetails = await Tokencontract.methods
        .allowance(currAddr, config.lottery)
        .call();
      setAllowance(viewTokenDetails);
    }
  }

  async function getclaimstatus(_id) {
    // var {provider, address} = await getprovider();
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      if (localStorage.getItem('claim')) {
        var address = localStorage.getItem('claim')
        var Round = localStorage.getItem('round')
        console.log(address, Round, 'useEffect', Ticid);
        if (address === currAddr && _id === Round) {
          setclaim(false)
        } else {
          setclaim(true)
        }
      } else {
        setclaim(true)
      }
    }
  }

  async function getTokenbal() {
    try {
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        // const myAccounts = await web3.eth.getAccounts();
        const currAddr = address;

        console.log(
          currAddr,
          "currAddrcurrAddrcurrAddrcurrAddrcurrAddrcurrAddr"
        );
        var CoursetroContract = new web3.eth.Contract(
          LotterytokenABI,
          config.lotteryToken
        );
        var tokenBal = await CoursetroContract.methods
          .balanceOf(currAddr)
          .call();

        var tokenBalance = tokenBal / config.decimalvalues;

        console.log(tokenBalance, "saran");

        Set_TokenBalance(tokenBalance);
      }
    } catch (err) {
      console.log("errror123:", err);
    }
  }

  async function enableIn() {
    setoutcss("block");
    setincss("none");
  }
  async function enableOut(id) {
    setoutcss("none");
    setincss("block");
    // var {provider, address} = await getprovider();
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);

      var viewLotteryDetails = await LotteryContract.methods
        .viewUserInfoForLotteryId(currAddr, id, 0, 100000)
        .call();

      var viewLotteryId = await LotteryContract.methods.viewLottery(id).call();

      var startdate = new Date(viewLotteryId.startTime * 1000);
      var enddate = new Date(viewLotteryId.endTime * 1000);

      var amountPrize =
        viewLotteryId.amountCollectedInCake / 1000000000000000000;

      var minusTruesaryFees = (amountPrize * 30) / 100;

      var finalPrizeAmount = amountPrize - minusTruesaryFees;

      SetTicStartlotteryTime(startdate);
      SetTicEndlotteryTime(enddate);
      Set_TiclotteryPrice(
        viewLotteryId.priceTicketInCake / 1000000000000000000
      );
      SetTiclotteryPrizeAmount(finalPrizeAmount);
      SetTicrewardsBreakdown(viewLotteryId.rewardsBreakdown);
      SetTicuserTicketCount(viewLotteryDetails[3]);
      var digits = viewLotteryId.finalNumber.toString().split("");
      var realDigits = digits.map(Number);

      SetTicfinalNumber(realDigits);
      console.log('issue', TicfinalNumber)
      SetTicid(id);
    }
  }
  async function claimRewards() {
    // var {provider, address} = await getprovider();
    if (provider) {
      var bracArray = [];
      var bracArrayTic = [];

      for (var i = 0; i < currentWinningNumber.length; i++) {
        var data = currentWinningNumber[i];
        var digits = data.toString().split("");
        var realDigits = digits.map(Number);
        var match = 10;

        if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3] &&
          realDigits[2] == TicfinalNumber[2] &&
          realDigits[1] == TicfinalNumber[1]
        ) {
          match = 5;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3] &&
          realDigits[2] == TicfinalNumber[2]
        ) {
          match = 4;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3]
        ) {
          match = 3;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4]
        ) {
          match = 2;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5]
        ) {
          match = 1;
        } else if (realDigits[6] == TicfinalNumber[6]) {
          match = 0;
        }
        console.log(match, "matchmatchmatchmatch");
        if (match != 10) {
          bracArrayTic.push(currentWinningNumberId[i]);
          bracArray.push(match);
        }
      }

      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);

      if (bracArrayTic.length > 0) {
        console.log(bracArrayTic, "saran");
        var lotDetailsnewWithdraw = await LotteryContract.methods
          .viewNumbersAndStatusesForTicketIds(bracArrayTic)
          .call();

        console.log(
          lotDetailsnewWithdraw,
          "lotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdraw",
          Ticid,
          bracArrayTic,
          bracArray
        );

        if (!lotDetailsnewWithdraw[1][0]) {
          try {
            var lotDetailsnewWithdraw = await LotteryContract.methods
              .claimTickets(Ticid, bracArrayTic, bracArray)
              .send({ from: currAddr }).then((result) => {
                localStorage.setItem('claim', currAddr);
                localStorage.setItem('round', Ticid);
                toast.success("Rewards claimed successfully", toasterOption);
                window.location.reload();
              }).catch((error) => {
                toast.warn("User Rejected Rewards", toasterOption);
                localStorage.removeItem('claim');
              })
          } catch {
            localStorage.removeItem('claim');
          }
        } else {
          toast.warn("Rewards Already claimed", toasterOption);
        }
      } else {
        toast.warn("There is no winning ticket to claim", toasterOption);
      }
    }

  }
  async function viewticketdetails(id = "") {
    // var {provider, address} = await getprovider();
    getclaimstatus(id)
    console.log(id, "idididididididiidiididdididi");
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
      var tickid = Ticid;
      if (id != "") tickid = id;

      SetTicid(tickid);

      var lotDetails = await LotteryContract.methods
        .viewUserInfoForLotteryId(currAddr, tickid, 0, 1000000)
        .call();

      console.log(
        lotDetails,
        "lotDetailslotDetailslotDetailslotDetailslotDetails"
      );

      SetcurrentWinningNumber(lotDetails[1]);
      SetcurrentWinningNumberId(lotDetails[0]);

      var viewLotteryId = await LotteryContract.methods
        .viewLottery(tickid)
        .call();
      console.log(viewLotteryId, "viewLotteryId")
      var digits = viewLotteryId.finalNumber.toString().split("");
      console.log(digits, "digists")
      var realDigits = digits.map(Number);
      console.log(realDigits, "realDigits")
      SetfinalNumber(realDigits)
     // alert("view ticket de : "+realDigits)
      SetTicfinalNumber(realDigits);
      console.log(TicfinalNumber, "TicfinalNumber")
    }
  }

  async function getLotteryDet() {
    try {
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        // var currAddrALL = await web3.eth.getAccounts();
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
        var currentLotteryId = await LotteryContract.methods
          .currentLotteryId()
          .call();
        console.log(currentLotteryId, 'lotteryID')
        SetcurrentLottery(currentLotteryId);
       

        var viewLotteryDetails = await LotteryContract.methods
          .viewUserInfoForLotteryId(currAddr, currentLotteryId, 0, 100000)
          .call();

        console.log(
          viewLotteryDetails,
          "viewLotteryDetailsviewLotteryDetailsviewLotteryDetailsviewLotteryDetails"
        );

        var getUserLottery = await LotteryContract.methods
          .getUserLottery(currAddr)
          .call();

        const UniqueLott = {};
        getUserLottery.forEach(function (x) {
          if (x != currentLotteryId) UniqueLott[x] = (UniqueLott[x] || 0) + 1;
        });

        var histArray = [];

        for (const property in UniqueLott) {
          var obj = {};
          var ticketid = property;
          var count = UniqueLott[property];
          var hisTick = await LotteryContract.methods
            .viewLottery(ticketid)
            .call();

          var enddate = new Date(hisTick.endTime * 1000);

          obj = { ticketid: ticketid, count: count, enddate: enddate };

          histArray.push(obj);
        }

        SetallHist(histArray);

        console.log(UniqueLott, "UniqueLottUniqueLottUniqueLott");
        var viewLotteryId = await LotteryContract.methods
          .viewLottery(currentLotteryId)
          .call();

        console.log(
          viewLotteryId,
          "viewLotteryIdviewLotteryIdviewLotteryIdviewLotteryId"
        );

        var amountPrize =
          viewLotteryId.amountCollectedInCake / 1000000000000000000;

        var minusTruesaryFees = (amountPrize * 30) / 100;

        var finalPrizeAmount = amountPrize;

        var startdate = new Date(viewLotteryId.startTime * 1000);
        var enddate = new Date(viewLotteryId.endTime * 1000);
        SetEndlotteryTime(Date.parse(new Date(enddate)));


        // var adddays = new Date(lastregister);
        // adddays.setDate(adddays.getDate() + 5);
        // var timestamp = Date.parse(new Date(adddays));


        SetStartlotteryTime(startdate);
        setTimerStart(1);
        Set_lotteryPrice(viewLotteryId.priceTicketInCake / 1000000000000000000);
        SetlotteryPrizeAmount(finalPrizeAmount);
        SetuserTicketCount(viewLotteryDetails[3]);
        SetrewardsBreakdown(viewLotteryId.rewardsBreakdown);
        SetfinalNumber(viewLotteryId.finalNumber)
       // alert("All History :"+viewLotteryId.finalNumber)
        console.log(viewLotteryId.rewardsBreakdown, 'rewards')
        console.log(startdate, 'starttime')
        console.log(Endlotterytime, 'endtime')
        await historychange("id", currentLotteryId);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  async function historychange(type, e) {
    try {
     // alert("Type : "+type)
      var currentLotteryHis = parseInt(currentLottery) - 1;
      var id = currentLotteryHis;
      if (type == "curr") {
        var id =
          e.target.value &&
            e.target.value <= currentLotteryHis &&
            e.target.value != 0 &&
            e.target.value != ""
            ? e.target.value
            : currentLotteryHis;
      } else if (type == "next") {
        var next = parseInt(historyId) + 1;
        var id =
          next <= currentLotteryHis && next != 0 && next != ""
            ? next
            : currentLotteryHis;
      } else if (type == "prev") {
        var next = parseInt(historyId) - 1;
        var id =
          next <= currentLotteryHis && next != 0 && next != ""
            ? next
            : currentLotteryHis;
      } else if (type == "id") {
        var id = parseInt(e) - 1;
      }
      // var {provider, address} = await getprovider();

      if (provider) {

        var web3 = new Web3(provider);
        // var currAddr = await web3.eth.getAccounts();
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);

        SethistoryId(id);

        var viewLotteryId = await LotteryContract.methods
          .viewLottery(id)
          .call();

        var viewLotteryDetails = await LotteryContract.methods
          .viewUserInfoForLotteryId(currAddr, id, 0, 100000)
          .call();

        var startdate = new Date(viewLotteryId.startTime * 1000);
        var enddate = new Date(viewLotteryId.endTime * 1000);

        var amountPrize =
          viewLotteryId.amountCollectedInCake / 1000000000000000000;

        var minusTruesaryFees = (amountPrize * 0) / 100;

        var finalPrizeAmount = amountPrize - minusTruesaryFees;

        SethisStartlotteryTime(startdate);
        SethisEndlotteryTime(enddate);
        Set_hislotteryPrice(
          viewLotteryId.priceTicketInCake / 1000000000000000000
        );
        SethislotteryPrizeAmount(finalPrizeAmount);
        SethisuserTicketCount(viewLotteryDetails[3]);
        SethisrewardsBreakdown(viewLotteryId.rewardsBreakdown);
        var digits = viewLotteryId.finalNumber.toString().split("");
        var realDigits = digits.map(Number);
        console.log(realDigits, "realDigitsrealDigitsrealDigitsrealDigits");

        SetfinalNumber(realDigits);
      //  alert("Real Dights :"+realDigits)
       // alert(realDigits)
      }
    } catch (err) { }
  }

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if (currAddr) {
      getTokenbal();
    }
  };

  const inputChange = async (e) => {
    console.log("inputChange");
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "lotteryAmt":
          if (value != "" && isNaN(value) == false && value > 0) {
            Set_lotteryAmt(value);
            ValidateError.lotteryAmt = "";
            let calc = await PriceCalculate({ lotteryAmt: value });
            Set_ValidateError(ValidateError);
            let estimate = calc * config.decimalvalues;
            let estimateamount = await convert(estimate);
            let myallowance = parseFloat(Allowance);
            console.log(myallowance, '--------631')
            // myallowance = await convert(myallowance);
            // myallowance = parseFloat(myallowance);
            console.log(estimate, typeof estimateamount, myallowance, "allowance", estimateamount < myallowance)
            if (estimate < myallowance) {
              Set__ApproveCallStatus("done");
            }
          } else {
            ValidateError.lotteryAmt = "Enter Valid price";
            Set_ValidateError(ValidateError);
            Set_lotteryAmt(value);
          }
          break;
      }
      // window.$('#Validation_PlaceABid').click();
      console.log(value, "targetvalue");
      if (value > 100) {
        toast.warn("Please enter equal or below 100", toasterOption);
        Set_lotteryAmt("")
      }
    }
  };
  const PriceCalculate = async (data = {}) => {
    var price =
      typeof data.lotteryAmt != "undefined" ? data.lotteryAmt : lotteryAmt;
    // var weii = price * config.decimalvalues;
    // var per = (weii * config.fee) / 1e20;
    // var mulWei = parseFloat(weii - per);

    // (_priceTicket *
    //   _numberTickets *
    //   (_discountDivisor + 1 - _numberTickets)) / _discountDivisor;
    // alert(lotteryPrice);
    // alert(price);

    var totamount = (lotteryPrice * price );

    // console.log(lotteryPrice,price,totamount)

    Set_YouWillGet(totamount);
    return totamount;
    // Set_MultipleWei(mulWei);
  };
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
      Set_ValidateError(ValidateError);
    } else {
      var ValidateError = {};
      ValidateError.lotteryAmt = '"Quantity" must be a number';
      Set_ValidateError(ValidateError);
    }
    // if(e.target.value>100){
    //   toast.warn("Please enter Equal or below 100 Quantity")
    // }
  };

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n.toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
      return 0;
    }

  }


  async function approveNow(e) {
    try {

      setloader(true)
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        var currAddr = address;
        var Refitplus = new web3.eth.Contract(
          LotterytokenABI,
          config.lotteryToken
        );
        var sendAmt = YouWillGet + 1000 * config.decimalvalues;
        var finamount = await convert(sendAmt);
        if (TokenBalance < YouWillGet) {
          toast.error("Insufficent Balance", toasterOption);
          setloader(false)
          return false;
        }

        Set__ApproveCallStatus("processing");
        await Refitplus.methods
          .approve(config.lottery, finamount.toString())
          .send({ from: Accounts })
          .then(async (result) => {
            if (result && result != "") {
              Set__ApproveCallStatus("done");
              toast.success("Token Approved Successfully", toasterOption);
              setloader(false)
            }
          })
          .catch((error) => {
            toast.error("Approve failed", toasterOption);
            setloader(false)
          });
      }
    } catch (err) {
      console.log("err", err)
      setloader(false)
    }
  }

  async function BuyNow(e) {
    try {
      setloader(true)
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
        var sendAmt = lotteryAmt * config.decimalvalues;

        var CurrencyLotterId = await LotteryContract.methods
          .currentLotteryId()
          .call();

        var ticketArray = [];

        for (var i = 0; i < lotteryAmt; i++) {
          var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
          var randomNo =
            parseFloat(randomNo.toString().split("").reverse().join("")) *
            Math.sign(randomNo);
          var final = randomNo + 1000000;

          ticketArray.push(final);
        }

        await LotteryContract.methods
          .buyTickets(CurrencyLotterId, ticketArray)
          .send({ from: Accounts })
          .then(async (result) => {
            toast.success("Ticket Purchased Successfully", toasterOption);
            setloader(false)
          })
          .catch((error) => {
            console.log("buyerror:", error);
            toast.error("Approve failed", toasterOption);
            setloader(false)
          });
      }
    } catch (err) {
      console.log("222", err);
      setloader(false)
    }
    window.location.reload();
  }
  // Banner Countdown Timer
  const currentDate = new Date();

  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    var currDate = new Date();
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span></span>
        {/* <span className="timer_dots">:</span> */}
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        {/* <span className="timer_dots">:</span> */}
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Mins</span>
        </span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Seconds</span>
        </span>
        {/* <span className="timer_dots">:</span> */}
        {/* <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span> */}
      </div>
    );
  };
  console.log(Endlotterytime, '********')
  const { classes, theme } = props;
  // const open = Boolean(anchorEl);
  console.log(finalNumber, "finalNumber")
  console.log(Allowance, "allowance")
  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <CssBaseline />
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      {/* Header APP */}
      <HeaderDashboard
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/*  side Menu */}
      <SidebarLinksAfterlogin classes1={classes} open={open} />
      <div className="overlay"></div>
      <main className={classes.content + " dashContent"}>
        <div className={classes.toolbar} />
        <div className="dashPages">
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} className="m-auto">
              <div>
                <h2 className="inner_title text-center">DecentraDice Lottery</h2>
                <div className="container">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="ticket_details">
                        <h2>{lotteryPrizeAmount.toFixed(6)} USDT</h2>
                        <h4>in prizes!</h4>
                        {new Date(Endlotterytime) >= new Date() ? (
                          <Button
                            className="primary_btn"
                            data-toggle="modal"
                            data-target="#buy_tickets_modal"
                          >
                            Buy Tickets
                          </Button>
                        ) : (
                          <text className="primary_btn">closed</text>
                        )}

                        {/* <Button
                          className="primary_btn"
                          data-toggle="modal"
                          data-target="#buy_tickets_modal"
                        >
                          Buy Tickets
                        </Button> */}
                      </div>
                      <div className="get_tickets_section mt-5">
                        <h2 className="text-center">Get your tickets now!</h2>
                        <div className="d-flex align-items-center justify-content-center">
                          {/* <Countdown
                              date={`${year}-12-01T12:00:00`}
                              renderer={renderer}
                            /> */}
                          {Endlotterytime && timerStart == 1 &&
                            <Countdown
                              date={parseInt(Endlotterytime)}
                              renderer={renderer}
                            >
                            </Countdown>

                            //   <Countdown date={Date.now() + 5000}>
                            // </Countdown>
                          }
                          <h3 className="m-0"> until the draw</h3>
                        </div>
                      </div>
                      <div class="card card_draw">
                        <div class="card-header">
                          <h4 class="card-title">Next Draw</h4>
                          <p>
                            #{currentLottery} | <b>Draw</b>: {moment(Endlotterytime).format('LLLL')}
                          </p>
                        </div>
                        <div class="card-body">
                          <div className="d-flex justify-content-between w-75">
                            <h4>
                              <b>Prize Pot</b>
                            </h4>
                            <h2 className="prize_pot_value">{lotteryPrizeAmount.toFixed(6)} USDT</h2>
                          </div>
                          {/* { new Date(Endlotterytime) >= new Date() ?
                          <div className="d-flex justify-content-between w-75">
                            <h4>
                              <b>Your Claimable</b>
                            </h4>
                            <h2 className="prize_pot_value">{lotteryPrizeAmount.toFixed(6)} USDT</h2>
                          </div> : <></>
                            } */}
                          <div className="d-flex justify-content-between w-75">
                            <h4>
                              <b>Your tickets</b>
                            </h4>
                            <h4>
                              You have <b>{userTicketCount}</b> ticket this
                              round
                            </h4>
                          </div>
                          {new Date(Endlotterytime) >= new Date() ? (
                            <Button
                              className="primary_btn mr-1"
                              data-toggle="modal"
                              data-target="#buy_tickets_modal"
                            >
                              Buy Tickets
                            </Button>
                          ) : (
                            <text className="primary_btn mr-1">closed</text>
                          )}
                          {userTicketCount > 0 && (
                            <Button
                              className="primary_btn"
                              data-toggle="modal"
                              data-target="#view_winning_tickets"
                              onClick={() => viewticketdetails(currentLottery)}
                            >
                              View Tickets
                            </Button>
                          )}
                          <div
                            class="accordion draw_accordian"
                            id="accordionExample"
                          >
                            <div class="card">
                              <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link btn-block text-left p-0"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    Details
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseOne"
                                class="collapse"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                              >
                                <div class="card-body">
                                  <p>
                                    Match the winning number in the same order
                                    to share prizes. Current prizes up for
                                    grabs:
                                  </p>
                                  <div className="draw_accordian_panel">
                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 1</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[0]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 2</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[1]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* 1648123774 */}
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 3</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[2]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 4</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[3]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 5</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[4]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    <div className="draw_accordian_panel_single">
                                      <h4>Match first 6</h4>
                                      <h3>
                                        {(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[5]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      {/* <p>~$3,899</p> */}
                                    </div>

                                    {/* <div className="draw_accordian_panel_single">
                                      <h4>Burn</h4>
                                      <h3>
                                        {(
                                         {((lotteryPrizeAmount *rewardsBreakdown[6]) /10000 ).toFixed(2)}{" "}
                                        USDT
                                      </h3>
                                      <p>~$3,899</p>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="ticket_details">
                        <h2>Are you a winner?</h2>
                        <div>
                          {" "}
                          <img
                            src={require("../assets/images/gift.png")}
                            style={{ width: "150px" }}
                            className="my-4 img-fluid"
                            alt="Image"
                          />
                        </div>
                        <Button className="primary_btn">Check Now</Button>
                        <div>
                          <h3 className="mt-2 text-center">
                            No prizes to collect... <br />
                            Better luck next time!
                          </h3>
                        </div>
                      </div> */}

                      <div className="finished_rounds">
                        <h2 className="text-center mt-5">Finished Rounds</h2>
                        <ul
                          class="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link active"
                              id="pills-all_history-tab"
                              data-toggle="pill"
                              href="#pills-all_history"
                              role="tab"
                              aria-controls="pills-all_history"
                              aria-selected="true"
                            >
                              All History
                            </a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link"
                              id="pills-your_history-tab"
                              data-toggle="pill"
                              href="#pills-your_history"
                              role="tab"
                              aria-controls="pills-your_history"
                              aria-selected="false"
                            >
                              Your History
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-all_history"
                            role="tabpanel"
                            aria-labelledby="pills-all_history-tab"
                          >
                            <div class="card card_draw">
                              <div class="card-header">
                                <div>
                                  <h4 class="card-title m-0">
                                    Round{" "}
                                    <input
                                      className="round_inp"
                                      defaultValue={historyId}
                                      value={historyId}
                                      onChange={(e) => historychange("curr", e)}
                                    />
                                  </h4>
                                  <p className="m-0">
                                    {moment(hisEndlotterytime).format('LLLL')}
                                  </p>
                                </div>
                                <div className="rounds_btns">
                                  <Button onClick={() => historychange("prev")}>
                                    <i class="bi bi-arrow-left-short"></i>
                                  </Button>
                                  <Button onClick={() => historychange("next")}>
                                    <i class="bi bi-arrow-right-short"></i>
                                  </Button>
                                </div>
                              </div>
                              <div class="card-body">
                                <div className="d-flex justify-content-between">
                                  <h4>
                                    <b>Winning Number</b>
                                  </h4>
                                  <div className="winning_series">
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_01.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[6]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_02.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[5]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_03.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[4]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_04.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[3]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_05.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[2]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_06.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{finalNumber[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="accordion draw_accordian"
                                  id="accordionExample"
                                >
                                  <div class="card">
                                    <div class="card-header" id="headingOne">
                                      <h2 class="mb-0">
                                        <button
                                          class="btn btn-link btn-block text-left p-0"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target="#collapseTwo"
                                          aria-expanded="true"
                                          aria-controls="collapseTwo"
                                        >
                                          Details
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id="collapseTwo"
                                      class="collapse"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample"
                                    >
                                      <div class="card-body">
                                        <p>
                                          Match the winning number in the same
                                          order to share prizes. Current prizes
                                          up for grabs:
                                        </p>
                                        {/* <p>
                                          Total players this round: <b>2025</b>
                                        </p> */}
                                        <p className="m-0">Prize pot:</p>
                                        {/* <h2 className="m-0">~$420,548</h2> */}
                                        <p className="m-0">
                                          {hislotteryPrizeAmount.toFixed(6)} USDT
                                        </p>
                                        <div className="draw_accordian_panel">
                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 1</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[0]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p> */}
                                            {/* <p>0.28 USDT each 1454 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 2</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[1]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>4.67 USDT each 133 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 3</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[2]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>86.23 USDT each 12 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 4</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[3]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>689.84 USDT each 3 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 5</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[4]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>4139.06 USDT each 1 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 6</h4>
                                            <h3>
                                              {(
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[5]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>0 Winners</p> */}
                                          </div>

                                          {/* <div className="draw_accordian_panel_single">
                                            <h4>Burn</h4>
                                            <h3>
                                              {(
                                                ((hislotteryPrizeAmount /
                                                  1000000000000000000) *
                                                  2) /
                                                100
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                             <p>~$3,899</p> 
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-your_history"
                            role="tabpanel"
                            aria-labelledby="pills-your_history-tab"
                          >
                            <div
                              class="card card_draw"
                              id="outer"
                              style={{ display: outcss }}
                            >
                              <div class="card-header">
                                <div>
                                  <h4 class="card-title m-0">Rounds</h4>
                                  {/* <p className="m-0">Drawn Jul 12, 2021, 11:30 AM</p> */}
                                </div>
                                {/* <div className="rounds_btns">
                                    <Button><i class="bi bi-arrow-left-short"></i></Button>
                                    <Button><i class="bi bi-arrow-right-short"></i></Button>
                                  </div> */}
                              </div>
                              <div class="card-body">
                                {/* <h4 className="text-center">
                                  No lottery history found
                                </h4>
                                <h4 className="text-center">
                                  Buy tickets for the next round!
                                </h4>
                                <div className="text-center">
                                  <Button
                                    className="primary_btn"
                                    data-toggle="modal"
                                    data-target="#buy_tickets_modal"
                                  >
                                    Buy Tickets
                                  </Button>
                                </div> */}
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td>Rounds</td>
                                      <td>Date</td>
                                      <td>Your Tickets</td>
                                      <td>View</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allHist.map((data, key) => {
                                      return (
                                        <tr>
                                          <td>{data.ticketid}</td>
                                          <td>{data.enddate.toString()}</td>
                                          <td>{data.count}</td>
                                          <td>
                                            {/* <i class="bi bi-trophy-fill"></i> */}
                                            {/* <a
                                              href="#"
                                              
                                            > */}
                                            <i
                                              onClick={() =>
                                                enableOut(data.ticketid)
                                              }
                                              class="bi bi-chevron-right"
                                            ></i>
                                            {/* </a> */}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div
                              class="card card_draw"
                              id="inner"
                              style={{ display: incss }}
                            >
                              <div class="card-header">
                                <div className="d-flex align-items-center">
                                  <Button onClick={() => enableIn()}>
                                    <i
                                      class="bi bi-arrow-left-short"
                                      style={{ fontSize: "18px" }}
                                    ></i>
                                  </Button>
                                  <h4 class="card-title m-0">Round {Ticid} </h4>
                                  <p className="m-0">
                                    {hisEndlotterytime.toString()}
                                  </p>
                                </div>
                              </div>
                              <div class="card-body">
                                <div className="d-flex justify-content-between">
                                  <h4>
                                    <b>Winning Number</b>
                                  </h4>
                                  <div className="winning_series">
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_01.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[6]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_02.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[5]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_03.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[4]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_04.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[3]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_05.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[2]}</p>
                                    </div>
                                    <div className="winning_series_panel">
                                      <img
                                        src={require("../assets/images/circle_06.svg")}
                                        style={{ width: "70px" }}
                                        alt="Image"
                                      />
                                      <p>{TicfinalNumber[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  className="primary_btn"
                                  data-toggle="modal"
                                  data-target="#view_winning_tickets"
                                  onClick={() => viewticketdetails()}
                                >
                                  View Tickets
                                </Button>
                                <div
                                  class="accordion draw_accordian"
                                  id="accordionExample"
                                >
                                  <div class="card">
                                    <div class="card-header" id="headingOne">
                                      <h2 class="m-0">
                                        <button
                                          class="btn btn-link btn-block text-left p-0"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target="#collapseTwo"
                                          aria-expanded="true"
                                          aria-controls="collapseTwo"
                                        >
                                          Details
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id="collapseTwo"
                                      class="collapse"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample"
                                    >
                                      <div class="card-body">
                                        <p>
                                          Match the winning number in the same
                                          order to share prizes. Current prizes
                                          up for grabs:
                                        </p>
                                        {/* <p>
                                          Total players this round: <b>2025</b>
                                        </p> */}
                                        <p className="m-0">Prize Pot:</p>
                                        {/* <h2 className="m-0">~$420,548</h2> */}
                                        <p className="m-0">
                                          {TiclotteryPrizeAmount} USDT
                                        </p>
                                        <div className="draw_accordian_panel">
                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 1</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[0]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p> */}
                                            {/* <p>0.28 USDT each 1454 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 2</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[1]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>4.67 USDT each 133 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 3</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[2]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>86.23 USDT each 12 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 4</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[3]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>689.84 USDT each 3 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 5</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[4]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>4139.06 USDT each 1 Winners</p> */}
                                          </div>

                                          <div className="draw_accordian_panel_single">
                                            <h4>Match first 6</h4>
                                            <h3>
                                              {(
                                                (TiclotteryPrizeAmount *
                                                  TicrewardsBreakdown[5]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                            {/* <p>~$3,899</p>
                                            <p>0 Winners</p> */}
                                          </div>

                                          {/* <div className="draw_accordian_panel_single">
                                            <h4>Burn</h4>
                                            <h3>
                                              {(
                                                ((TiclotteryPrizeAmount /
                                                  1000000000000000000) *
                                                  2) /
                                                100
                                              ).toFixed(2)}{" "}
                                              USDT
                                            </h3>
                                             <p>~$3,899</p> 
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="how_play">
                        <h2 className="text-center mt-5">How to Play</h2>
                        <p className="text-center">
                          If the digits on your tickets match the winning
                          numbers in the correct order, you win a portion of the
                          prize pool. Simple!
                        </p>
                        <GridContainer className="justify-content-center">
                          <GridItem sm={6} md={4}>
                            <div className="how_play_panel">
                              <p className="text-right mb-0">
                                <samll>STEP 1</samll>
                              </p>
                              <h2>Buy Tickets</h2>
                              <p>
                                Prices are set when the round starts, 10 DecentraDice in per ticket.
                              </p>
                            </div>
                          </GridItem>
                          <GridItem sm={6} md={4}>
                            <div className="how_play_panel">
                              <p className="text-right mb-0">
                                <samll>STEP 2</samll>
                              </p>
                              <h2>Wait for the Draw</h2>
                              <p>
                                There are two draws every day: one every 12
                                hours.
                              </p>
                            </div>
                          </GridItem>
                          <GridItem sm={6} md={4}>
                            <div className="how_play_panel">
                              <p className="text-right mb-0">
                                <samll>STEP 3</samll>
                              </p>
                              <h2>Check for Prizes</h2>
                              <p>
                                Once the round’s over, come back to the page and
                                check to see if you’ve won!
                              </p>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>

                      <div className="winning_criteria mt-5">
                        <GridContainer>
                          <GridItem sm={12} md={6}>
                            <h2>Winning Criteria</h2>
                            <h4>
                              The digits on your ticket must match in the
                              correct order to win.
                            </h4>
                            <p>
                              Here’s an example lottery draw, with two tickets,
                              A and B.
                            </p>
                            <ul>
                              <li>
                                <strong>Ticket A:</strong> The first 3 digits
                                and the last 2 digits match, but the 4th digit
                                is wrong, so this ticket only wins a “Match
                                first 3” prize.
                              </li>
                              <li>
                                <strong>Ticket B:</strong> Even though the last
                                5 digits match, the first digit is wrong, so
                                this ticket doesn’t win a prize.
                              </li>
                            </ul>
                            <p>
                              Prize brackets don’t ‘stack’: if you match the
                              first 3 digits in order, you’ll only win prizes
                              from the ‘Match 3’ bracket, and not from ‘Match 1’
                              and ‘Match 2’.
                            </p>
                          </GridItem>
                          <GridItem sm={12} md={6}>
                            <div className="text-center">
                              <img
                                src={require("../assets/images/winning.png")}
                                className="img-fluid"
                                alt="Image"
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>

                      <div className="winning_criteria mt-5">
                        <GridContainer>
                          <GridItem sm={12} md={6}>
                            <h2>Prize Funds</h2>
                            <p>
                              The prizes for each lottery round come from three
                              sources:
                            </p>
                            <h4>Ticket Purchases</h4>
                            <ul>
                              <li>
                                100% of the DecentraDice paid by people buying
                                tickets that round goes back into the prize
                                pools.
                              </li>
                            </ul>
                            <h4>Rollover Prizes</h4>
                            <ul>
                              <li>
                                After every round, if nobody wins in one of the
                                prize brackets, the unclaimed DecentraDice for that
                                bracket rolls over into the next round and are
                                redistributed among the prize pools.
                              </li>
                            </ul>
                            <h4>DecentraDice Injections</h4>
                            <ul>
                              <li>
                                An average total of 10000 DecentraDice from the
                                treasury is added to lottery rounds over the
                                course of a week. This DecentraDice is of course also
                                included in rollovers!
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem sm={12} md={6}>
                            <div className="text-center">
                              <div className="prize_details">
                                <div>
                                  <h2>Digits Matched</h2>
                                  <h2>Prize Pool Allocation</h2>
                                </div>
                                <ul>
                                  <li>
                                    <span>Matches first 1</span>
                                    <span>{rewardsBreakdown[0] ? (rewardsBreakdown[0] / 100) : 0}%</span>
                                  </li>
                                  <li>
                                    <span>Matches first 2</span>
                                    <span>{rewardsBreakdown[1] ? (rewardsBreakdown[1] / 100) : 0}%</span>
                                  </li>
                                  <li>
                                    <span>Matches first 3</span>
                                    <span>{rewardsBreakdown[2] ? (rewardsBreakdown[2] / 100) : 0}%</span>
                                  </li>
                                  <li>
                                    <span>Matches first 4</span>
                                    <span>{rewardsBreakdown[3] ? (rewardsBreakdown[3] / 100) : 0}%</span>
                                  </li>
                                  <li>
                                    <span>Matches first 5</span>
                                    <span>{rewardsBreakdown[4] ? (rewardsBreakdown[4] / 100) : 0}%</span>
                                  </li>
                                  <li>
                                    <span>Matches first 6</span>
                                    <span>{rewardsBreakdown[5] ? (rewardsBreakdown[5] / 100) : 0}%</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </main>

      <WalletModal />

      {/* Choose Collection Modal */}
      <div
        class="modal fade primary_modal"
        id="buy_tickets_modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="buy_tickets_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="buy_tickets_modalLabel">
                Buy Tickets
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Buy
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      onChange={inputChange}
                      value={lotteryAmt}
                      onKeyUp={onKeyUp}
                      autoComplete="off"
                      id="lotteryAmt"
                      name="lotteryAmt"
                    />
                    {ValidateError.lotteryAmt && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.lotteryAmt}
                      </span>
                    )}
                    <div className="text-white text-right">
                      {config.LotterytokenSymbol} Balance: {TokenBalance}
                    </div>
                  </div>
                  <div className="form-group col-md-12 d-flex justify-content-between">
                    <label className="primary_label" htmlFor="name">
                      Cost ({config.LotterytokenSymbol})
                    </label>
                    <label className="primary_label" htmlFor="name">
                      <span>{YouWillGet.toFixed(2)}</span>{" "}
                      {config.LotterytokenSymbol}
                    </label>
                  </div>
                  {/* <div className="form-group col-md-12 d-flex justify-content-between">
                    <label className="primary_label" htmlFor="name">
                      <b>0%</b> Bulk discount
                    </label>
                    <label className="primary_label" htmlFor="name">
                      <span>~{YouWillGet}</span> {config.LotterytokenSymbol}
                    </label>
                  </div> */}

                  <div className="form-group col-md-12 d-flex justify-content-between">
                    <label className="primary_label" htmlFor="name">
                      You pay
                    </label>
                    <label className="primary_label" htmlFor="name">
                      <span>~{YouWillGet.toFixed(2)}</span>{" "}
                      {config.LotterytokenSymbol}
                    </label>
                  </div>
                </div>
                <div className="text-center">
                  {ApproveCallStatus == "init" ||
                    ApproveCallStatus == "processing" ? (
                    <Button className="create_btn" onClick={() => approveNow()}>
                      {loader && (
                        <i class="fas fa-spinner fa-spin mr-2"></i>
                      )} Approve
                    </Button>
                  ) : (
                    <Button
                      className="create_btn"
                      type="button"
                      onClick={() => BuyNow()}
                    >{loader && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                      Buy Now
                    </Button>
                  )}
                </div>
                {/* <div><label className="primary_label">0.3%  Bonus fee</label></div> */}
                <p className="buy_ticket_notes">
                  "Buy Instantly" chooses random numbers, with no duplicates
                  among your tickets. Prices are set before each round starts,
                  {/* equal to $5 at that time */}. Purchases are final.
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Winning Modal Tickets */}
      <div
        class="modal fade primary_modal"
        id="view_winning_tickets"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="view_winning_ticketsCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="view_winning_ticketsLabel">
                Round 204
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h4>Winning Number</h4>
              <div className="winning_series">
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_01.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[6]}</p>
                </div>
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_02.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[5]}</p>
                </div>
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_03.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[4]}</p>
                </div>
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_04.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[3]}</p>
                </div>
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_05.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[2]}</p>
                </div>
                <div className="winning_series_panel">
                  <img
                    src={require("../assets/images/circle_06.svg")}
                    style={{ width: "40px" }}
                    alt="Image"
                  />
                  <p>{finalNumber[1]}</p>
                </div>
              </div>
              <h4>Your Tickets</h4>
              <div className="d-flex justify-content-between">
                <p>Total Tickets :</p>
                <p>{currentWinningNumber.length}</p>
              </div>
              {/* <div className="d-flex justify-content-between">
                <p>Winning Tickets :</p>
                <p>1</p>
              </div> */}
              <div className="d-flex justify-content-between">
                <p className="mb-0">#{Ticid}</p>
                {/* <p className="mb-0">Matched first 1</p> */}
              </div>

              {/* <span className="winning_round_selected">3</span> */}
              {currentWinningNumber.map((data) => {
                var digits = data.toString().split("");
                var realDigits = digits.map(Number);
                return (
                  <div>
                    {/* <div className="d-flex justify-content-between mt-2">
                      <p className="mb-0">#2933085</p>
                    </div> */}
                    <div className="winning_rounds">
                      {TicfinalNumber[6] == realDigits[6] ? (
                        <p className="winning_round_selected">
                          {realDigits[6]}
                        </p>
                      ) : (
                        <span>{realDigits[6]}</span>
                      )}

                      {TicfinalNumber[6] == realDigits[6] &&
                        TicfinalNumber[5] == realDigits[5] ? (
                        <p className="winning_round_selected">
                          {realDigits[5]}
                        </p>
                      ) : (
                        <span>{realDigits[5]}</span>
                      )}

                      {TicfinalNumber[6] == realDigits[6] &&
                        TicfinalNumber[5] == realDigits[5] &&
                        TicfinalNumber[4] == realDigits[4] ? (
                        <p className="winning_round_selected">
                          {realDigits[4]}
                        </p>
                      ) : (
                        <span>{realDigits[4]}</span>
                      )}

                      {TicfinalNumber[6] == realDigits[6] &&
                        TicfinalNumber[5] == realDigits[5] &&
                        TicfinalNumber[4] == realDigits[4] &&
                        TicfinalNumber[3] == realDigits[3] ? (
                        <p className="winning_round_selected">
                          {realDigits[3]}
                        </p>
                      ) : (
                        <span>{realDigits[3]}</span>
                      )}

                      {TicfinalNumber[6] == realDigits[6] &&
                        TicfinalNumber[5] == realDigits[5] &&
                        TicfinalNumber[4] == realDigits[4] &&
                        TicfinalNumber[3] == realDigits[3] &&
                        TicfinalNumber[2] == realDigits[2] ? (
                        <p className="winning_round_selected">
                          {realDigits[2]}
                        </p>
                      ) : (
                        <span>{realDigits[2]}</span>
                      )}

                      {TicfinalNumber[6] == realDigits[6] &&
                        TicfinalNumber[5] == realDigits[5] &&
                        TicfinalNumber[4] == realDigits[4] &&
                        TicfinalNumber[3] == realDigits[3] &&
                        TicfinalNumber[2] == realDigits[2] &&
                        TicfinalNumber[1] == realDigits[1] ? (
                        <p className="winning_round_selected">
                          {realDigits[1]}
                        </p>
                      ) : (
                        <span>{realDigits[1]}</span>
                      )}
                    </div>
                    <br />
                  </div>
                );
              })}
              <div className="mt-2 mb-2 text-center">
                {new Date(Endlotterytime) >= new Date() ? (
                  <Button
                    className="create_btn"
                    type="button"
                    disabled="true"
                  >
                    Claim
                  </Button>
                ) : claim ? (
                  <Button
                    className="create_btn"
                    type="button"
                    onClick={() => claimRewards()}
                  >
                    Claim
                  </Button>
                ) : (
                  <Button
                    className="create_btn"
                    type="button"
                    disabled="true"
                  >
                    Rewards claimed
                  </Button>
                )}

              </div>
              {/* <span>6</span>
                <span>4</span>
                <span>2</span>
                <span>6</span>
                <span>2</span>
              </div> */}

              {/* <div className="d-flex justify-content-between mt-2">
                <p className="mb-0">#2933085</p>
              </div>
              <div className="winning_rounds">
                <span>2</span>
                <span>1</span>
                <span>9</span>
                <span>3</span>
                <span>7</span>
                <span>4</span>
              </div>
              <div className="mt-2 text-center">
                <Button className="create_btn" type="button">
                  Claim
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Lottery.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Lottery);
