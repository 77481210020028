let EnvName          = "production";
let Front_URL        = "";
let Back_URL         = "https://api-dedice.maticz.in/";
let v1Url            = "";
let RpcUrl           = "";
let limit            = "12";
let limitMax         = "3000";
let fee              = 2500000000000000000;
let decimalvalues    = 1000000000000000000;
let toFixed          = 6;
let IPFS_IMG         = "https://ipfs.io/ipfs";
const singleType     = 721; 
const multipleType   = 1155;
var providercon      = null;
let networkVersion   = 56; //mainnet -56,testnet-97
let currencySymbol   = "BNB";
let equalTokenSymbol = "WBNB";
let tokenSymbol      = "USDT";
const ownerAddr      = "0x9Be2D469f49cdFACEB86B01C853bbc7910d4AdFa";
var liverpcUrls ='';
var livechainid = 0;


// Testnet

// const lottery ="0x0a819f28Ce7B99aE5C08Da221a9D031A0B32733C"
// const lotterAdmin    ="0x3778EcF0b729ecd4DdcA96e2D9f588314Ad73F6D";
// const lotteryToken        = "0x63260f581583aba25353501b9174701c1b9055fe";

// const singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517"; // testnet 0x96e4ED44bc0dA71e61404920778Ee4C0e1C62b71
// const WbnbtokenAddr  = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // testne  t0xd38745B8B190Fb25B4d195ea299E64EebDFa227c
// const PankukuAddr    = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254"; //testnet  0x7b3064583157f29a9c74b4218E94aDBb3a6aeD38

//Livenet 
const lotteryToken   = "0x63260F581583ABA25353501b9174701C1b9055Fe";
const lottery        = "0x9AF7c2F3fDFF4d211892Ef4B62De09bEc0fB4808";
const lotterAdmin    ="0x9Be2D469f49cdFACEB86B01C853bbc7910d4AdFa";

const singleContract =  "0x96e4ED44bc0dA71e61404920778Ee4C0e1C62b71";
const WbnbtokenAddr  = "0xd38745B8B190Fb25B4d195ea299E64EebDFa227c";
const PankukuAddr    = "0x28da3db8d1b6446c97ea09f718e40a081ad665e1";


// Testnet
// const lottery        = "0x09416115E926b440E0eb9C79530e5c0B768BF411"
// 0x039d96132CCF09D24a09EbEf7624A46A2A57F7Dd

const userContract721  = "";
const userContract1155 = "";
let toasterOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
if (EnvName === "production") {
  Front_URL = "https://decentradice.io/";
  Back_URL  = "https://api-dedice.maticz.in/";
  v1Url     = "https://lottery.refitplusdefi.com/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";
  RpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  liverpcUrls = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  livechainid = 97;
  networkVersion= 97;
} else if (EnvName === "demo") {
  Front_URL = "https://lottery.refitplusdefi.com/";
  Back_URL  = "https://lottery.refitplusdefi.com/";
  v1Url     = "https://lottery.refitplusdefi.com/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";
  RpcUrl = "https://bsc-dataseed1.binance.org:443/";
  liverpcUrls = "https://bsc-dataseed1.binance.org:443/";
  livechainid = 56;
} else {
  Front_URL = "http://localhost:3000/";
  Back_URL = "http://localhost:2057/";
  v1Url = "http://localhost:2057/v1";
  // Back_URL  = "http://212.71.233.123:5325/";
  // v1Url     = "http://212.71.233.123:5325/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";
  RpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  liverpcUrls = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  livechainid = 97;
  networkVersion= 97;
}

console.log("livechainid : ",liverpcUrls)
  
let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  equalTokenSymbol: equalTokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  userContract721: userContract721,
  userContract1155: userContract1155,
  ownerAddr: ownerAddr,
  singleType: singleType,
  multipleType: multipleType,
  ipfsurl: IPFS_IMG,
  providercon: providercon,
  WbnbtokenAddr: WbnbtokenAddr,
  PankukuAddr: PankukuAddr,
  fee: fee,
  lotteryToken: lotteryToken,
  lottery: lottery,
  RpcUrl: RpcUrl,
  LotterytokenSymbol: "USDT",
  liverpcUrls: liverpcUrls,
  livechainid: livechainid,
};

export default key;
 // "homepage": "https://nft.pankuku.net",